<template>
  <button 
    class="animate-all relative overflow-visible"
    :class="{ 'bg-red pointer-events-none': !addItemAllowed}"
    @click="addToCart"
  >
    <transition name="fade" mode="out-in">
      <div
        v-if="errorMessageBuyButton && activeProduct === product.partNo"
        class="tooltip absolute px-12 py-8 text-xsmall rounded w-full lg:w-[max-content] lg:max-w-[200px] bg-black right-0 bottom-56"
      >
        {{ errorMessageBuyButton }}
        <GlobalsLinkHelper
          v-if="customerServicePage && !isMobile && !hideCustomerService"
          class="ml-6 text-xsmall underline"
          :to="customerServicePage.url"
        >
          {{ errorCta }}
        </GlobalsLinkHelper>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <img
        v-if="productAddedToCart"
        :src="'/lottie-animations/check-svg.svg?url'"
        :height="24"
        :width="24"
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
      <img
        v-else-if="addToCartLoading"
        :src="'/lottie-animations/spinner-svg-white.svg?url'"
        :height="24"
        :width="24"
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
      <XCircleIcon 
        v-else-if="!addToCartLoading && !addItemAllowed" 
        class="text-error h-24 w-24 absolute top-1/2 transform -translate-y-1/2 animate-all"
      />
    </transition>
    <span 
      key="text"
      class="animate-all"
      :class="{
        'opacity-0': addToCartLoading || productAddedToCart || !addItemAllowed
      }"
    >
      {{ buttonText }}
    </span>
    <slot />
  </button>
</template>

<script setup lang="ts">
import { CartType, type IProduct, type IVariant } from '~/api-types';
import { ref } from 'vue';
import { useCartStore } from '~/store/cart';
import { useGlobalContentStore } from '~/store/globalContent';
import { useErrorStore } from '~/store/error';
import XCircleIcon from '@heroicons/vue/24/outline/esm/XCircleIcon';
import { useUiStore } from '~/store/ui';
import { storeToRefs } from 'pinia';

const uiStore = useUiStore();
const { isMobile } = storeToRefs(uiStore);
const globalContentStore = useGlobalContentStore();
const cartStore = useCartStore();
const errorStore = useErrorStore();
const customerServicePage = globalContentStore.pageSettings?.customerServicePage;

interface Props {
  product: IProduct,
  selectedVariant: IVariant,
  buttonText: string,
  cartType?: CartType,
  selectedQuantity?: number
  preventPurchase?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  cartType: CartType.Standard,
  selectedQuantity: 1,
});

const addToCartLoading = ref(false);
const productAddedToCart = ref(false);
const addItemAllowed = ref(true);

const hideCustomerService = computed(() => {
  return errorStore.hideCustomerService;
});

const activeProduct = computed(() => {
  return errorStore.activeProduct;
});

const errorMessageBuyButton = computed(() => {
  return errorStore.errorMessageBuyButton;
});

const errorCta = computed(() => {
  return globalContentStore.sharedResources.genericErrorCta;
});

const addToCart = async() => {
  if (props.preventPurchase) return;
  
  addToCartLoading.value = true;
  try {
    await cartStore.addItem({
      product: props.product,
      selectedVariant: props.selectedVariant,
      cartType: props.cartType,
      selectedQuantity: props.selectedQuantity,
    });
    productAddedToCart.value = true;
    setTimeout(() => { productAddedToCart.value = false; }, 2000);
  } catch (e) {
    addItemAllowed.value = false;
    setTimeout(() => { addItemAllowed.value = true; }, 5000);
    // errorStore.displayError({ message: sharedResources.genericError });
  } finally {
    addToCartLoading.value = false;
  }
};

watch(
  () => errorMessageBuyButton.value,
  () => {
    if (errorMessageBuyButton.value) {
      setTimeout(() => errorStore.clearBuyButtonError(), 5000);
    }
  }
);

</script>

<style scoped>
.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  border-width: 7px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  @apply left-1/2 transform -translate-x-1/2 lg:right-24 lg:translate-x-0 lg:left-[auto] lg:ml-[7px];
}
</style>
<style>
    .product-page .tooltip:not(.bookmark) {
        @apply max-w-[250px] left-1/2 transform -translate-x-1/2;
        &::after {
    @apply left-1/2 transform -translate-x-1/2 right-[auto] ml-0;
  }
}
</style>
